<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="900"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="pa-7">
          <v-card-title class="pa-0 pb-3">
            <h2>{{ formTitle }} - {{ cast.name }}</h2>
          </v-card-title>

          <!-- タブ -->
          <v-tabs
            class="flex-grow-0"
            v-model="selectedTab"
            centered grow show-arrows
            color="primary"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.id"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items
            class="pt-5"
            v-model="selectedTab"
            continuous
          >
            <!-- 基本情報 -->
            <v-tab-item>
              <v-form ref="form-master">
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model.trim="cast.name"
                      label="名前"
                      required
                      counter="20"
                      :rules="[valiRules.required, valiRules.max20, valiRules.uniqueInObjs(casts, orgCastName, 'name')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="cast.age"
                      type="number"
                      max="100"
                      label="年齢"
                      :rules="[valiRules.required, valiRules.age]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2"
                    v-if="rankMaster.length"
                  >
                    <v-select
                      v-model="cast.rank"
                      label="ランク"
                      :items="rankMaster"
                      item_value="rank"
                      item-text="rank"
                      item-color="primary"
                      :rules="[valiRules.required]"
                    ></v-select>
                  </v-col>
                </v-row>
                <!-- ３サイズ -->
                <v-row>
                  <v-col cols="2">
                    <v-text-field
                      class="mt-0 pt-0"
                      v-model="cast.height"
                      type="number"
                      max="200"
                      label="身長 (cm)"
                      :rules="[valiRules.required, valiRules.max200num, valiRules.min1num]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      class="mt-0 pt-0"
                      v-model="cast.bust"
                      type="number"
                      max="200"
                      label="B (cm)"
                      :rules="[valiRules.required, valiRules.max200num, valiRules.min1num]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      class="mt-0 pt-0"
                      v-model="cast.waist"
                      type="number"
                      max="200"
                      label="W (cm)"
                      :rules="[valiRules.required, valiRules.max200num, valiRules.min1num]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      class="mt-0 pt-0"
                      v-model="cast.hip"
                      type="number"
                      max="200"
                      label="H (cm)"
                      :rules="[valiRules.required, valiRules.max200num, valiRules.min1num]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      class="mt-0 pt-0"
                      v-model="cast.cup"
                      label="カップ表記"
                      :items="bustAry"
                      item-color="primary"
                    ></v-select>
                  </v-col>
                </v-row>

                <!-- 入店日 -->
                <v-row class="align-center mt-0">
                  <v-col cols="3">
                    <v-menu top
                      v-model="openPickerStartDate"
                      :close-on-content-click="false"
                      :nudge-left="60"
                      transition="scroll-y-reverse-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="cast.start_date"
                          label="入店日"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="cast.start_date"
                        locale="ja"
                        no-title
                        :day-format="date => numericDate(date)"
                        header-color="primary"
                        event-color="primary"
                        @input="openPickerStartDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="9">
                    <icon-info icon="lightbulb-on-outline" class="ml-n1" :square="true">
                      入店日が過去1ヶ月以内のキャストがHPホーム画面の「新人情報」に表示されます。<br />
                    </icon-info>
                  </v-col>
                </v-row>

                <!-- ステータススイッチ -->
                <v-row justify="start">
                  <v-col cols="12" class="py-0">
                    <p>ステータス</p>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-switch
                      class="mt-0 pt-0 pl-2"
                      v-model="cast.is_new"
                      inset
                      hide-details
                      label="新人"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="cast.is_soku"
                      inset
                      hide-details
                      label="即ひめ"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="cast.is_dummy"
                      inset
                      hide-details
                      label="ダミー"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="cast.is_active"
                      inset
                      hide-details
                      label="アクティブ"
                    ></v-switch>
                  </v-col>
                </v-row>

                <v-card-actions class="mt-7 pa-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    :disabled="!changedMaster"
                    color="primary"
                    @click="formBasicInfoSubmitted"
                  >
                    登録
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

            <!-- オプション -->
            <v-tab-item>
              <v-form ref="form-option">
                <!-- オプション -->
                <v-row>
                  <v-col cols="12" class="d-flex align-center">
                    <p>オプションを選択</p>
                  </v-col>
                  <v-col cols="12"
                    v-if="!optionMaster.length"
                  >
                    <v-card flat>
                      <v-card-text>
                        <p>オプションが登録されていません。<br />「HP基本設定」→「オプション」から登録してください。</p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-chip-group
                      v-model="selectedOptionIs"
                      column
                      multiple
                      active-class="accent"
                    >
                      <v-chip
                        v-for="option in optionMaster"
                        :key="option.option_id"
                      >
                        {{ option.option_name }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="checkedAllOptions"
                      class="mt-0"
                      label="全選択"
                      color="primary"
                      hide-details
                      @click="selectAllOptions"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-card-actions class="mt-3 pa-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    :disabled="!changedOptions"
                    color="primary"
                    @click="formOptionSubmitted"
                  >
                    登録
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

            <!-- HPプロフィール -->
            <v-tab-item>
              <v-form ref="form-profile">
                <v-row>
                  <v-col cols="12" class="pt-5 pb-0">
                    <v-text-field
                      class="mt-0 pt-0"
                      v-model.trim="inputProfile.catch_copy"
                      label="キャッチコピー"
                      counter="20"
                      required
                      hint="キャスト一覧のパネル画像の上部に表示されます"
                      :rules="[valiRules.required, valiRules.max20]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <p>自由コメント枠1（店舗コメント等）</p>
                    <site-color-editor
                      v-if="loadedProfile"
                      v-bind:text.sync="inputProfile.comment_primary"
                      :apiAdmin="apiAdmin"
                      :shopData="shopData"
                      :siteSetting="siteSetting"
                    />
                    <small
                      v-if="siteSetting.shop_id"
                    >
                      背景・文字は実際のHPのカラーです
                    </small>
                  </v-col>
                  <v-col cols="6">
                    <p>自由コメント枠2</p>
                    <site-color-editor
                      v-if="loadedProfile"
                      v-bind:text.sync="inputProfile.comment_secondary"
                      :apiAdmin="apiAdmin"
                      :shopData="shopData"
                      :siteSetting="siteSetting"
                    />
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    :disabled="!changedProfile"
                    color="primary"
                    @click="formSiteProfileSubmitted"
                  >
                    登録
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

            <!-- タグ -->
            <v-tab-item>
              <v-form ref="form-tag">
                <v-row>
                  <v-col cols="12">
                    <p>キャストのタイプを選択</p>
                  </v-col>
                  <v-col cols="12"
                    v-if="!tagMaster.length"
                  >
                    <v-card flat>
                      <v-card-text>
                        <p>タグが登録されていません。<br />「HP基本設定」→「キャストタグ」から登録してください。</p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-chip-group
                      class="mb-3"
                      v-model="selectedTagIds"
                      column
                      multiple
                      active-class="accent"
                    >
                      <v-chip
                        v-for="tag in tagMaster"
                        :key="tag.tag_id"
                      >
                        {{ tag.tag_name }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                  <v-col cols="12">
                    <icon-info icon="lightbulb-on-outline" class="ml-n1" :square="true">
                      選択したタグはキャストのプロフィール内に表示されます。<br />
                      タグは4つまで選べます。
                    </icon-info>
                  </v-col>
                </v-row>

                <v-card-actions class="mt-3 pa-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    :disabled="!changedTags"
                    color="primary"
                    @click="formTagSubmitted"
                  >
                    登録
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

            <!-- SNS -->
            <v-tab-item>
              <v-form ref="form-sns">
                <v-row>
                  <v-col cols="4">
                    <v-select
                      class="mt-0"
                      v-model="selectedSns"
                      label="SNSを選ぶ"
                      multiple
                      hide-details
                      :items="snsMaster"
                      return-object
                      item-text="sns_name"
                      item-color="primary"
                    ></v-select>
                  </v-col>
                  <v-col cols="8" class="d-flex align-end">
                    <icon-info icon="lightbulb-on-outline" class="ml-n1" :square="true">
                      SNSはアカウントページヘのリンクボタンとしてプロフィール内に表示されます。
                    </icon-info>
                  </v-col>
                  <v-col cols="12">
                    <v-card class="sns-input pt-4 pb-1 px-5"
                      flat tile
                      v-for="sns in selectedSns"
                      :key="sns.sns_name"
                    >
                      <v-row>
                        <v-col cols="3">
                          <v-text-field
                            v-model="sns.sns_name"
                            label="SNS名"
                            :prepend-inner-icon="mdiIconName(sns.icon_name)"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="sns.sns_account"
                            label="アカウントID"
                            counter="50"
                            :rules="[valiRules.required, valiRules.max50]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            v-model="sns.sns_account_url"
                            label="アカウントURL"
                            counter="100"
                            :rules="[valiRules.url, valiRules.max100]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>

                <v-card-actions class="mt-5 pa-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    :disabled="!changedSns"
                    color="primary"
                    @click="formSnsSubmitted"
                  >
                    登録
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

            <!-- 画像 -->
            <v-tab-item>
              <v-form ref="form-image">
                <v-row v-if="uploadedImages.length">
                  <draggable
                    class="draggable d-flex flex-wrap"
                    v-model="uploadedImages"
                    animation="100"
                    @sort="changedImageOrder = true"
                  >
                    <v-col cols="2"
                      v-for="(image, index) in uploadedImages"
                      :key="image.image_id"
                    >
                      <v-img
                       cover
                       content-class="cast-image"
                       min-height="150"
                       max-height="200"
                       :src="image.image_url"
                      >
                        <v-btn
                          class="mb-5 mr-n3"
                          fab x-small absolute bottom right
                          color="primary"
                          @click="deleteImage(index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-img>
                    </v-col>
                  </draggable>
                </v-row>
                <v-row v-else no-gutters>
                  <p class="pa-7">
                    登録されている画像がありません。画像をアップロードしてください。
                  </p>
                </v-row>
                <v-row no-gutters>
                  <icon-info icon="alert-octagon" class="mt-5" :square="true">
                    パネル写真は最大6つまで登録できます。<br />
                    画像のサイズ上限は1MBになります。
                  </icon-info>
                  <v-spacer></v-spacer>
                  <btn-tip
                    class="mt-3 mr-3"
                    :disabled="!changedImageOrder"
                    tip="パネル並び順を登録" icon="sort-ascending"
                    fab small
                    elevation="7"
                    color="primary"
                    @click="updateImageOrder()"
                  ></btn-tip>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      v-model="uploadImages"
                      accept="image/png, image/jpeg"
                      multiple counter outlined
                      show-size
                      prepend-icon="mdi-image-multiple"
                      label="アップロードする画像を選択"
                      hint="[shiftキー]、[ctlキー／cmdキー]を使って複数の画像を選択すると一度に複数をアップロードできます"
                      persistent-hint
                      :rules="[valiRules.images]"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          color="accent"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    :disabled="!uploadImages.length"
                    color="primary"
                    @click="formImagesSubmitted"
                  >
                    画像をアップロード
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

            <!-- 動画 -->
            <v-tab-item>
              <v-form ref="form-video">
                <v-row v-if="uploadedVideos.length">
                  <v-col cols="6"
                    class="cast-video"
                    v-for="(video, index) in uploadedVideos"
                    :key="video.video_id"
                  >
                    <video
                      controls
                      :src="video.video_url"
                      :poster="video.poster_url"
                    >
                    </video>
                    <v-btn
                      class="mb-10 mr-5"
                      fab x-small absolute bottom right
                      color="primary"
                      @click="deleteVideo(index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-else no-gutters>
                  <p class="pa-7">
                    登録されている動画がありません。動画をアップロードしてください。
                  </p>
                </v-row>
                <v-row no-gutters>
                  <icon-info icon="alert-octagon" class="mt-3" :square="true">
                    動画は最大2つまで登録できます。<br />
                    動画のサイズ上限は10MBになります。
                  </icon-info>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      v-model="uploadVideo"
                      accept="video/mp4, video/mov, video/wav"
                      outlined chips show-size
                      prepend-icon="mdi-video"
                      label="アップロードする動画を選択"
                      hint="一度にアップロードできる動画はひとつです"
                      :rules="[valiRules.required, valiRules.video]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <p class="mt-2 mb-5">動画読込み時の画像を登録（必要であれば）</p>
                  <v-col cols="12">
                    <v-file-input
                      v-model="uploadVideoPoster"
                      accept="image/png, image/jpeg"
                      outlined show-size
                      prepend-icon="mdi-image"
                      label="画像を選択"
                      hint="サイズ上限：1MB" persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          color="accent"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    :disabled="!uploadVideo"
                    color="primary"
                    @click="formVideoSubmitted"
                  >
                    動画をアップロード
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

          </v-tabs-items>

          <!-- ローダー -->
          <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute top
          color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import moment from 'moment'
import draggable from 'vuedraggable'
import $literals from '@/literals.js'
import { API_ENDPOINT, SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import QuillWithSiteColor from '@/components/_QuillWithSiteColor.vue'
import IconInfo from '@/components/_IconInfo.vue'
import BtnWithTip from '@/components/_BtnWithTip.vue'

export default {
  components: {
    draggable: draggable,
    'site-color-editor': QuillWithSiteColor,
    'icon-info': IconInfo,
    'btn-tip': BtnWithTip,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
    casts: {
      type: Array,
      required: true
    }
  },

  //**************************************************
  // データ
  //**************************************************
  data() {
    return {
      cast: {},
      selectedOptionIs: [],
      selectedTagIds: [],
      selectedSns: [],
      inputProfile: {comment_primary: '', comment_secondary: ''},
      uploadedImages: [],
      uploadedVideos: [],
      siteSetting: {},
      optionMaster: [],
      tagMaster: [],
      rankMaster: [],
      snsMaster: [],
      uploadImages: [],
      uploadVideo: null,
      uploadVideoPoster: null,
      bustAry: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
      selectedTab: 0,
      openForm: false,
      openPickerStartDate: false,
      changedMaster: false,
      changedOptions: false,
      changedProfile: false,
      changedSns: false,
      changedTags: false,
      changedImageOrder: false,
      checkedAllOptions: false,
      loadedProfile: false,
      formType: '',
      comeBack: {},
      submitCallback: null,
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      systemApi: new ApiTool(API_ENDPOINT + '/', this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
      tabs: [
        {id: 0, name: '基本情報'},
        {id: 1, name: 'オプション'},
        {id: 3, name: 'HPコメント'},
        {id: 4, name: 'タグ'},
        {id: 5, name: 'SNS'},
        {id: 6, name: 'パネル'},
        {id: 7, name: 'ムービー'},
      ],
    }
  },

  //**************************************************
  // 算出
  //**************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    formTitle() {
      return this.formType === 'create' ? '新規キャスト登録' : 'キャスト情報更新'
    },
    formButton() {
      return this.formType === 'create' ? '登録' : '更新'
    },
    isCreate() {
      return this.formType === 'create' ? true : false
    },
    mdiIconName() {
      return name => 'mdi-' + name
    },
    orgCastName() {
      const cast = this.casts.find( cast => cast.cast_id === this.cast.cast_id )
      return cast !== undefined ? cast.name : ''
    },
    selectedOptions() {
      const objArray = []
      this.selectedOptionIs.map( index => {
        objArray.push(this.optionMaster[index])
      })
      return objArray
    },
    selectedTags() {
      const objArray = []
      this.selectedTagIds.map( index => {
        objArray.push(this.tagMaster[index])
      })
      return objArray
    },
    watchedSns() {
      return JSON.parse(JSON.stringify(this.selectedSns))
    },
    numericDate() {
      return date => moment(date).format('D')
    }
  },

  //**************************************************
  // 監視
  //**************************************************
  watch: {
    cast: {
      handler: function(now, old) {
        if (!this.isCreate && !old.cast_id ||
            old.cast_id !== now.cast_id
            // (!this.isCreate && (!old.btn || !now.btn))
        ) return

        this.changedMaster = true
      },
      deep: true
    },
    selectedOptionIs: function(now, old) {
      if (now === old) {
        this.changedOptions = false
      } else {
       this.changedOptions = true
     }
    },
    inputProfile: {
      handler: function(now, old) {
        if (this.isCreate ||
          (!now.cast_id && now.catch_copy) ||
          (now.cast_id > 0 && now.cast_id === old.cast_id)
        ) {
          this.changedProfile = true
        }
      },
      deep: true
    },
    selectedTagIds: function(now, old) {
      if (now !== old) {
        this.changedTags = true
      } else {
        return
      }

      if (now.length > 4) {
        this.selectedTagIds = [...old]
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningMaxSelectableItems, open: true}}
      }
    },
    watchedSns: {
      handler: function(now, old) {
        if (!old.length) return

        if (old.length !== now.length) {
          this.changedSns = true
        } else {
          for (let i = 0; i < now.length; i++) {
            for (let key in now[i]) {
              if (now[i][key] !== old[i][key]) this.changedSns = true
            }
          }
        }
      },
      deep: true
    },
  },

  //
  // ライフサイクル
  //
  created() {
    this.adminApi.setToken(this.serverToken)

    //フォームに必要なデータを取得
    Promise.all([
      this.adminApi.getReqWithAuth('option/').then(results => {
        if (!results || !results.length) return
        this.optionMaster = [...results]
      }),
      this.adminApi.getReqWithAuth('rank/').then(results => {
        if (!results || !results.length) return
        this.rankMaster = [...results]
      }),
      this.adminApi.getReqWithAuth('cast-tag/').then(results => {
        if (!results || !results.length) return
        this.tagMaster = [...results]
      }),
      this.systemApi.getReqSystemPublic('sns-master/').then(results => {
        if (!results || !results.length) return
        this.snsMaster = [...results]
      }),
      this.getSiteSetting(),
    ])
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  //**************************************************
  // メソッド
  //**************************************************
  methods: {
    //**************************************************
    //                    APIコール
    //**************************************************
    // API req: GET site_setting
    getSiteSetting() {
      return this.adminApi.getReqWithAuth('site-setting/').then( result => {
        if (!result) return
        this.siteSetting = result
      })
    },

    //キャストオプション取得
    getCastOptions() {
      return this.publicApi.getReqSitePublic('cast/' + this.cast.cast_id + '/option/').then( results => {
        if (!results || !results.length) return
        //v-chip用にindex配列を作成
        results.map( option => {
          const index = this.optionMaster.findIndex( master => master.option_id === option.option_id )
          this.selectedOptionIs.push(index)
        })
      })
    },

    //キャストSNS取得
    getCastSns() {
      return this.publicApi.getReqSitePublic('cast/' + this.cast.cast_id + '/sns/').then( results => {
        if (!results || !results.length) return
        this.selectedSns = results
      })
    },

    //キャスト画像取得
    getCastImages() {
      return this.publicApi.getReqSitePublic('cast/' + this.cast.cast_id + '/image/').then( results => {
        if (!results || !results.length) return
        this.uploadedImages = results
      })
    },

    //キャストタグ取得
    getCastTags() {
      return this.publicApi.getReqSitePublic('cast/' + this.cast.cast_id + '/tag/').then( results => {
        if (!results || !results.length) return
        //v-chip用にindex配列を作成
        results.map( tag => {
          const index = this.tagMaster.findIndex( master => master.tag_id === tag.tag_id )
          this.selectedTagIds.push(index)
        })
      })
    },

    //キャスト動画取得
    getCastVideos() {
      return this.publicApi.getReqSitePublic('cast/' + this.cast.cast_id + '/video/').then( results => {
        if (!results || !results.length) return
        this.uploadedVideos = results
      })
    },

    //サイト用プロフィール取得
    getSiteProfile() {
      return this.publicApi.getReqSitePublic('cast/' + this.cast.cast_id + '/site-profile/').then( result => {
        if (!result) return
        this.inputProfile = {...result}
        this.loadedProfile = true
      })
    },
    //**************************************************

    //オプション全選択
    selectAllOptions() {
      this.selectedOptionIs = []
      if (this.checkedAllOptions) {
        this.optionMaster.forEach( (option, i) => this.selectedOptionIs.push(i) )
      }
      this.changedOptions = true
    },

    //**************************************************
    //オープン
    //**************************************************
    open(handdownData) {
      this.formType = handdownData.formType
      this.submitCallback = handdownData.submitCallback
      this.comeBack = handdownData.comeBack

      if (this.isCreate) {
        this.cast = {
          cast_id: 0,
          display_order: 0,
          is_active: true,
          is_dummy: false,
          is_new: true,
          is_soku: true,
          is_online: true,
          name: '',
          age: '20',
          height: '158',
          cup: 'C',
          bust: '85',
          waist: '57',
          hip: '85',
          start_date: moment(new Date()).format('YYYY-MM-DD'),
          rank: ''
        }
        this.loadedProfile = true
      } else {
        this.loading = true
        this.cast = { ...handdownData.updateData }
        this.cast.start_date = moment(this.cast.start_date).format('YYYY-MM-DD')

        //データ取得
        Promise.all([
          this.getCastOptions(),
          this.getSiteProfile(),
          this.getCastTags(),
          this.getCastSns(),
          this.getCastImages(),
          this.getCastVideos(),
        ])
        .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
        .then(() => this.loading = false)
      }
      this.selectedTab = 0
      this.changedMaster = false
      this.changedOptions = false
      this.changedProfile = false
      this.changedTags = false
      this.changedSns = false
      this.changedImageOrder = false
      this.openForm = true
    },

    //クローズ
    close() {
      this.selectedOptionIs = []
      this.selectedTagIds = []
      this.selectedSns = []
      this.uploadedImages = []
      this.uploadedVideos = []

      //quillエディタ設定
      this.inputProfile = {comment_primary: '', comment_secondary: ''},
      this.loadedProfile = false

      this.openPickerStartDate = false
      this.submitCallback = null
      this.loading = false
      this.openForm = false
    },

    //**************************************************
    //**************************************************
    //                    各サブミット
    //**************************************************
    //**************************************************
    //基本情報
    //**************************************************
    formBasicInfoSubmitted() {
      if (!this.$refs['form-master'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const apiPartial = 'cast/update/' + this.cast.cast_id

      const updateData = {
        shop_id: this.shopData.shop_id,
        cast_id: this.cast.cast_id,
        display_order: this.cast.display_order,
        is_active: this.cast.is_active,
        is_dummy: this.cast.is_dummy,
        is_new: this.cast.is_new,
        is_soku: this.cast.is_soku,
        is_online: this.cast.is_online,
        name: this.cast.name,
        age: this.cast.age,
        height: this.cast.height,
        cup: this.cast.cup,
        bust: this.cast.bust,
        waist: this.cast.waist,
        hip: this.cast.hip,
        start_date: this.cast.start_date,
        rank: this.cast.rank,
      }
      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('PUT', apiPartial, payload).then( response => {
        response.image_url = this.cast.image_url
        this.cast = {...response}
        this.changedMaster = false

        //キャストカードの情報を更新するためリターンデータを親に返す
        this.submitCallback(response, this.comeBack)

        if (this.isCreate) this.comeBack.index = 0
        this.formType = 'update'
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //オプション
    //**************************************************
    formOptionSubmitted() {
      if (this.isCreate) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningCreateBasicInfoFirst, open: true}}
        return
      }
      if (!this.$refs['form-option'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const apiPartial = 'cast/' + this.cast.cast_id + '/update/option/'
      const updateData = []

      this.selectedOptions.map( option => {
        updateData.push({
          shop_id: this.shopData.shop_id,
          cast_id: this.cast.cast_id,
          option_id: option.option_id
        })
      })

      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('PUT', apiPartial, payload).then(() => {
        this.changedOptions = false
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //HPプロフィール
    //**************************************************
    formSiteProfileSubmitted() {
      if (this.isCreate) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningCreateBasicInfoFirst, open: true}}
        return
      }
      if (!this.$refs['form-profile'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const apiPartial = 'cast/' + this.cast.cast_id + '/update/site-profile/'

      const updateData = {...this.inputProfile,
        shop_id: this.shopData.shop_id,
        cast_id: this.cast.cast_id,
      }
      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('PUT', apiPartial, payload).then(() => {
        this.changedProfile = false
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //タグ
    //**************************************************
    formTagSubmitted() {
      if (this.isCreate) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningCreateBasicInfoFirst, open: true}}
        return
      }
      if (!this.$refs['form-tag'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const apiPartial = 'cast/' + this.cast.cast_id + '/update/tag/'
      const updateData = []

      this.selectedTags.map( tag => {
        updateData.push({
          shop_id: this.shopData.shop_id,
          cast_id: this.cast.cast_id,
          tag_id: tag.tag_id
        })
      })

      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('PUT', apiPartial, payload).then(() => {
        this.changedTags = false
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //SNS
    //**************************************************
    formSnsSubmitted() {
      if (this.isCreate) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningCreateBasicInfoFirst, open: true}}
        return
      }
      if (!this.$refs['form-sns'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const apiPartial = 'cast/' + this.cast.cast_id + '/update/sns/'

      const updateData = []
      this.selectedSns.map( sns => {
        updateData.push({
          shop_id: this.shopData.shop_id,
          cast_id: this.cast.cast_id,
          sns_name: sns.sns_name,
          sns_account: sns.sns_account,
          sns_account_url: sns.sns_account_url,
        })
      })
      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('PUT', apiPartial, payload).then(() => {
        this.changedSns = false
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //画像
    //**************************************************
    formImagesSubmitted() {
      if (this.isCreate) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningCreateBasicInfoFirst, open: true}}
        return
      }
      if ((this.uploadedImages.length + this.uploadImages.length) > 6) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningMaxRegisterableItems, open: true}}
        return
      }
      if (!this.$refs['form-image'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const apiPartial = 'cast/' + this.cast.cast_id + '/create/image/'

      const formData = new FormData()
      this.uploadImages.map((file, index) => formData.append('cast-' + this.cast.cast_id + '-image-' + (index + 1), file) )
      formData.append('number_of_files', this.uploadImages.length)

      this.adminApi.apiReqWithData('POST', apiPartial, formData).then( response => {
        response.map( image => this.uploadedImages.push({...image}) )
        this.uploadImages = []

        if (!this.cast.image_url) {
          //キャストカードの画像を更新するためリターンデータを返す
          this.cast.image_url = response[0].image_url
          this.submitCallback(this.cast, this.comeBack)
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //画像の並び順
    //**************************************************
    updateImageOrder() {
      this.loading = true

      const apiPartial = 'cast/' + this.cast.cast_id + '/update/image-order/'

      const imageIds = []
      this.uploadedImages.map( image => imageIds.push(image.image_id) )

      const payload = JSON.stringify(imageIds)

      this.adminApi.apiReqWithData('PUT', apiPartial, payload).then(() => {
        //キャストカードの画像を更新
        this.cast.image_url = this.uploadedImages[0].image_url
        this.submitCallback(this.cast, this.comeBack)
        this.changedImageOrder = false

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //画像の削除
    //**************************************************
    deleteImage(index) {
      this.loading = true

      const apiPartial = 'cast/' + this.cast.cast_id + '/delete/image/' + this.uploadedImages[index].image_id

      this.adminApi.apiReqWithData('DELETE', apiPartial).then(() => {
        this.uploadedImages.splice(index, 1);

        //メイン画像ならキャストカードの画像も更新
        if (index === 0) {
          this.cast.image_url = this.uploadedImages.length ? this.uploadedImages[0].image_url : ''
          this.submitCallback(this.cast, this.comeBack)
        }

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //動画
    //**************************************************
    formVideoSubmitted() {
      if (this.isCreate) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningCreateBasicInfoFirst, open: true}}
        return
      }
      if (this.uploadedVideos.length >= 2) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningMaxRegisterableItems, open: true}}
        return
      }
      if (!this.$refs['form-video'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const apiPartial = 'cast/' + this.cast.cast_id + '/create/video/'

      const formData = new FormData()
      formData.append('video', this.uploadVideo)
      formData.append('poster', this.uploadVideoPoster)

      this.adminApi.apiReqWithData('POST', apiPartial, formData).then( response => {
        this.uploadedVideos.push(response)
        this.uploadVideo = null
        this.uploadVideoPoster = null
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },


    //**************************************************
    //動画の削除
    //**************************************************
    deleteVideo(index) {
      this.loading = true

      const apiPartial = 'cast/' + this.cast.cast_id + '/delete/video/' + this.uploadedVideos[index].video_id

      this.adminApi.apiReqWithData('DELETE', apiPartial).then(() => {
        this.uploadedVideos.splice(index, 1);

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },
  }
}
</script>

<style scoped>
.draggable {
  width: 100%;
}
video {
  max-width: 95%;
  max-height: 200px;
}
>>> .ql-container {
  min-height: 200px;
  max-height: 300px;
}
.theme--light .sns-input:nth-child(odd) {
  background-color: var(--content-bg-diff);
}
.theme--dark .sns-input:nth-child(odd) {
  background-color: var(--content-bg-dark-diff);
}
>>> .cast-image {
  position: relative;
  border: thin solid grey;
}
>>> .cast-video {
  position: relative;
}
</style>
