<template>
  <vue-editor
    v-model="localText"
    :editor-toolbar="editorToolbarConfig"
    useCustomImageHandler
    :placeholder="$attrs.placeholder"
    @image-added="uploadImage"
    @text-change="$emit('update:text', localText)"
    :style="{color: siteSetting.is_dark_theme ? 'white' : 'black',
            backgroundColor: siteSetting.secondary_color || 'grey',
            backgroundImage: 'url(' + siteSetting.bg_image_url + ')',
            backgroundSize: 'cover'}"
  />
</template>

<script>
import { ApiTool, CheckTokenError } from '@/module.js'
import { VueEditor } from 'vue2-editor'

export default {
  inheritAttrs: false,

  components: {
    'vue-editor': VueEditor,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
    siteSetting: {
      type: Object,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      localText: '',
      editorToolbarConfig: [
        // [{ 'header': 2 }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        [ // { align: '' },
          { align: 'center' }, { align: 'right' }],
        // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['link'],
        ['image'],
        ['clean']
      ],
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)
    this.localText = this.text
  },

  methods: {
    uploadImage: function(file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData()
      formData.append('upload_image', file)

      this.adminApi.apiReqWithData('POST', 'upload/image/', formData).then( url => {
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
      })
      .catch(error => CheckTokenError(error))
    }
  }
};
</script>

<style scoped>
.quillWrapper {
  border-radius: 6px;
}
>>> .ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--content-bg-diff);
}
>>> .ql-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-height: 300px;
  max-height: 600px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
>>> .ql-container::-webkit-scrollbar {
  display: none;
}
>>> .ql-editor h2 {
  font-size: 1.2em !important;
}
</style>
