<template>
  <v-container fluid class="content-wrap">
    <!-- コンテンツヘッダー -->
    <info-bar
      :btns="[
        {label:'キャスト', icon:'plus-box-multiple', tip:'新規キャスト追加', event:'addClicked'},
        {label:'並び登録', icon:'sort-ascending', tip:'並び順を登録', event:'sortExecClicked'},
      ]"
      @addClicked="openFormRegister('create')"
      @sortExecClicked="updateOrder()"
    >
      <template v-slot:content-info>
        登録：{{ casts.length }}名 ／ 本日出勤：{{ activeCount }}名
      </template>
    </info-bar>

    <!-- 説明 -->
    <v-row>
      <v-col>
        <icon-info icon="lightbulb-on-outline" :square="true">
          並べ替えは画像をドラッグ＆ドロップか <v-icon small>mdi-format-vertical-align-top</v-icon> ボタンで
        </icon-info>
      </v-col>
    </v-row>

    <v-row v-if="!casts.length">
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            キャストが登録されていません。<br />
            画面右上の「キャスト<v-icon>mdi-plus-box-multiple</v-icon>」から登録してください。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters v-else>
      <draggable
        class="draggable d-flex flex-wrap"
        v-model="casts"
        handle=".hero"
        animation="150"
        @sort="onSort"
      >
        <v-col cols="6" sm="4" md="3" lg="2"
          class="pa-2 d-flex flex-wrap"
          v-for="(cast, index) in casts"
          :key="cast.cast_id"
        >
          <v-hover
          v-slot="{ hover }"
          open-delay="300"
          >
            <v-card class="cast-card pb-2 d-flex flex-column transition-swing"
              :color="cast.is_active ? 'primary' : 'grey'"
              width="100%"
              :elevation="hover ? 10 : 2"
            >
              <!-- 画像 -->
              <v-card class="hero rounded-t"
                tile flat
                @click="openFormRegister('update', index)"
              >
                <v-img
                  class=""
                  :src="cast.image_url"
                  min-height="200"
                  max-height="250"
                >
                </v-img>

                <!-- 新人 -->
                <v-sheet
                  class="label-new"
                  color="primary"
                  v-if="cast.is_new"
                >
                  <v-icon>mdi-new-box</v-icon>
                </v-sheet>
              </v-card>

              <!-- 名前 -->
              <v-card-title class="px-2 pt-2 pb-0 d-flex text-subtitle-2 font-weight-bold">
                <p>{{ cast.name }}</p>
                <v-spacer></v-spacer>
                <v-icon v-if="cast.is_soku">mdi-run-fast</v-icon>
                <v-icon v-if="cast.is_dummy">mdi-drama-masks</v-icon>
                <v-icon v-if="!cast.is_active">mdi-account-off-outline</v-icon>
              </v-card-title>

              <!-- 3サイズ -->
              <v-card-text class="three-size py-0 pl-2 font-weight-bold">
                ({{ cast.age }}) {{ cast.height }}cm {{ cast.cup }}カップ
              </v-card-text>

              <!-- 本日出勤 -->
              <v-card-text class="mt-auto py-0 pl-2">
                <span class="text-button"
                  v-if="cast.shift_date"
                >
                  <v-icon small>mdi-clock-outline</v-icon>
                  {{ extractTime(cast.start_at) + "〜" + extractTime(cast.end_at) }}
                </span>
                <span v-else-if="!cast.is_active">
                  非アクティブ
                </span>
                <span v-else>
                  本日出勤なし
                </span>
              </v-card-text>

              <!-- スピードダイヤル -->
              <v-speed-dial
                v-model="cast.btn"
                absolute bottom right open-on-hover
                direction="top"
                transition="slide-y-reverse-transition"
              >
                <template v-slot:activator>
                  <v-btn
                    class="mb-n2 mr-n2"
                    v-model="cast.btn"
                    fab x-small
                    color="accent"
                  >
                    <v-icon v-if="cast.btn">mdi-close</v-icon>
                    <v-icon v-else>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <!-- ボタン -->
                <v-btn
                  fab small
                  color="primary"
                  @click="openFormRegister('update', index)"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
                <btn-tip
                  v-if="cast.is_active"
                  tip="即ひめ"
                  icon="run-fast"
                  fab small
                  elevation="5"
                  color="primary"
                  @click="toggleSoku(index)"
                ></btn-tip>
                <btn-tip
                  tip="先頭へ"
                  icon="format-vertical-align-top"
                  fab small
                  elevation="5"
                  color="primary"
                  @click="toTopClicked(index)"
                ></btn-tip>
                <btn-tip
                  tip="最後へ"
                  icon="format-vertical-align-bottom"
                  fab small
                  elevation="5"
                  color="primary"
                  @click="toLastClicked(index)"
                ></btn-tip>
                <v-btn
                  fab x-small
                  color="primary"
                  @click="openModalDelete(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-speed-dial>

            </v-card>
          </v-hover>
        </v-col>
      </draggable>
    </v-row>

    <!-- 登録フォーム -->
    <form-register
      ref="formRegister"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
      :casts="casts"
    ></form-register>

    <!-- 削除モーダル -->
    <modal-delete ref="modalDelete">
      <div v-html="modalMessage"></div>
    </modal-delete>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import draggable from 'vuedraggable'
import $literals from '@/literals.js'
import { ApiTool, BizHour, CheckTokenError } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ContentInfoBar from '@/components/_ContentInfoBar.vue'
import BtnWithTip from '@/components/_BtnWithTip.vue'
import ModalDelete from '@/components/_ModalDelete.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import FormRegister from '@/components/CastFormRegister.vue'
import IconInfo from '@/components/_IconInfo.vue'

export default {
  components: {
    draggable: draggable,
    'loader': Loader,
    'info-bar': ContentInfoBar,
    'btn-tip': BtnWithTip,
    'form-register': FormRegister,
    'modal-delete': ModalDelete,
    'modal-confirm': ModalConfirm,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      casts: [],
      activeCount: 0,
      dummyCount: 0,
      changedOrder: false,
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      bizHour: new BizHour(this.shopData)
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    watchedCasts() {
      return JSON.parse(JSON.stringify(this.casts))
    },
    extractTime() {
      return datime => moment(datime).format('HH:mm')
    },
  },

  //***************************************************
  //ウォッチ
  //***************************************************
  watch: {
    changedOrder(now) {
      if (now) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNotifyConfirmOrder, open: true}}
      }
    }
  },

  //***************************************************
  //ナビゲーションガード
  //***************************************************
  beforeRouteLeave(to, from, next) {
    if (this.changedOrder) {
      this.modalMessage = '<p>変更した並び順を登録してない様ですがページを移動してよろしいですか？</p>'

      const modalHanddown = {
        yesCallback: next,
        buttonLabel: '移動する',
      }
      this.$refs.modalConfirm.open(modalHanddown)
    } else {
      next()
    }
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.adminApi.setToken(this.serverToken)

    this.loading = true
    this.loadingMessage = $literals.MESSAGE.loaderLoading

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    .then(() => this.loading = false)
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async getData() {
      await this.getCasts()
      await this.getCastsShift()
    },

    //新規作成＆更新クリック
    openFormRegister(type, index) {
      const formHanddown = {
        formType: type,
        updateData: this.casts[index],
        submitCallback: this.updateCast,
        comeBack: { index: index }
      }
      this.$refs.formRegister.open(formHanddown)
    },

    //削除クリック
    openModalDelete(index) {
      this.modalMessage = '<p>「' + this.casts[index].name + '」を削除してよろしいですか？</p>'

      const modalHanddown = {
        submitCallback: this.deleteCast,
        comeBack: { index: index }
      }
      this.$refs.modalDelete.open(modalHanddown)
    },

    //先頭クリック
    toTopClicked(index) {
      const target = this.casts.splice(index, 1)
      this.casts.unshift(target[0])
      this.changedOrder = true
    },

    //最後クリック
    toLastClicked(index) {
      const target = this.casts.splice(index, 1)
      this.casts.push(target[0])
      this.changedOrder = true
    },

    //ドラッガブルハンドラー
    onSort() {
      this.changedOrder = true
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //キャストデータ取得
    //**************************************************
    getCasts() {
      return this.adminApi.getReqWithAuth('cast/').then( results => {
        if (!results || !results.length) return

        this.casts = results
        this.activeCount = results.filter( row => row.is_active === true ).length
        this.dummyCount = results.filter( row => row.is_dummy === true && row.is_active === true ).length
      })
    },

    //**************************************************
    //出勤データ取得
    //**************************************************
    getCastsShift() {
      const apiPartial = 'shift/date/' + this.bizHour.getBizOpening(new Date()).split(' ')[0]

      return this.adminApi.getReqWithAuth(apiPartial).then( results => {
        if (!results || !results.length) return

        results.map( shift => {
          this.casts.map( cast => {
            if (shift.cast_id === cast.cast_id) {
              cast.shift_date = shift.shift_date
              cast.start_at = shift.start_at
              cast.end_at = shift.end_at
            }
          })
        })
      })
    },

    //**************************************************
    // 更新 API req 完了後コールバック
    //**************************************************
    updateCast(response, cameBackData) {
      if (cameBackData.index === undefined) {
        this.casts.unshift(response)
      } else {
        this.casts.splice(cameBackData.index, 1, response)
      }
      this.snackbar = {...{color:'info', message: $literals.MESSAGE.successCreateSubmit, open: true}}
    },

    //**************************************************
    // 削除
    //**************************************************
    deleteCast(cameBackData) {
      const apiPartial = 'cast/delete/' + this.casts[cameBackData.index].cast_id

      this.adminApi.apiReqWithData('DELETE', apiPartial).then(() => {
        this.casts.splice(cameBackData.index, 1)
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalDelete.close() )
    },

    //**************************************************
    // 即ひめトグル
    //**************************************************
    toggleSoku(index) {
      const apiPartial = 'cast/update/' + this.casts[index].cast_id + '/is_soku'
      const payload = JSON.stringify({value: !this.casts.[index].is_soku})

      this.adminApi.apiReqWithData('PUT', apiPartial, payload).then(() => {
        this.casts[index].is_soku = !this.casts.[index].is_soku
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    // display_order更新
    //**************************************************
    updateOrder() {
      if (!this.changedOrder) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const castIds = []
      this.casts.map( cast => castIds.push({cast_id: parseInt(cast.cast_id)}) )

      this.adminApi.apiReqWithData('PUT', 'cast/update/order/', JSON.stringify(castIds)).then(() => {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => {
        this.loading = false
        this.changedOrder = false
      })
    }
  }
}
</script>

<style scoped>
.draggable {
  width: 100%;
}
.cast-card {
  overflow: hidden;
}
.cast-card .text-button {
  line-height: 1.5em;
  font-size: 1em;
}
.label-new {
  position: absolute;
  top: -11px;
  left: -28px;
  padding: 15px 25px 0px 25px;
  transform: rotate(-45deg);
}
</style>
